<template>
    <div class="shoplist">
        <div class="shopHomeTitle">
            <div class="centerWidth flex alignCenter marginAuto" style="text-align:left;height:100%;">{{textTitle}}</div>
        </div>
        <div class="centerWidth marginAuto" style="margin-bottom:20px;">
            <shops v-if="listData[page-1].length > 0" :shopList="listData[page-1]"></shops>
            <temp-data v-else></temp-data>
        </div>
        <el-pagination
        v-if="listData[page-1].length > 0"
        style="margin-top:37px;"
        background
        layout="prev, pager, next"
        :total="dataCount"
        :page-size="15"
        @current-change="currentChange">
        </el-pagination>
    </div>
</template>
<script>
import shops from '@/components/shops.vue'
import tempData from '@/components/tempData'
import { get_goods_type, get_goods_recommend, get_goods_plate, get_goods_list } from '@/utils/Api/goods'
export default {
    name:'shoplist',
    components:{
        shops,tempData
    },
    data(){
        return{
            listData:[],
            page:1,
            dataCount:0,
            textTitle:''
        }
    },
	computed:{
		searchQ(){
			return this.$route.query.q
		}
	},
	watch:{
		searchQ(){
			this.$router.go(0)
		}
	},
    created(){
        this.initial()
    },
    methods:{
        initial(){
            /* 
                type 1 推荐商品
                type 2 板块
                type 3 分类
            */
            let params = {
                page: this.page,
                limit: 15
            }
            if(this.$route.query.type && this.$route.query.type == '2'){
                params.plate_id = this.$route.query.id
            }
            if(this.$route.query.type && this.$route.query.type == '3'){
                params.type_id = this.$route.query.id
            }
			if(this.$route.query.type && this.$route.query.type == 'search'){
			    params.keywords = this.$route.query.q
			}
            console.log(this.$route.query.type)
            switch (this.$route.query.type) {
                case '1':
                    this.getgoodsRecommend(params)
                    break;
                case '2':
                    this.getGoodsList(params)
                    break;
                case '3':
                    this.getGoodsList(params)
                    break;
				case 'search':
				    this.getGoodsList(params)
				    break;
            }
            this.textTitle = document.title = this.$route.query.title
			if(this.$route.query.type == 'search'){
				this.textTitle = document.title = '搜索商品'
			}
        },
        async getGoodsList(params){
            let res = await get_goods_list(params)
            console.log(res)
            this.listData.push(res)
        },
        async getgoodsRecommend(params){
            let res = await get_goods_recommend(params)
            console.log(res)
            this.listData.push(res)
        },
        currentChange(value){
            this.page = value
            this.initial()
            this.scrollTop()
        }
    }
}
</script>
<style lang="scss" scoped>
.shoplist{}
.shopHomeTitle{
    width: 100%;
    height: 67px;
    background: #47D7E3;
    color: #fff;
    font-size: 24px;
}
</style>